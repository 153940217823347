.instruction {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  /* padding: 0 35px 35px 35px; */
  padding: 5% 10%;
  box-sizing: border-box;
  font-family: "Actay", sans-serif;
}
.instruction__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}
.instruction__text {
  color: var(--blue);
  /* font-size: 1.9rem; */
  font-size: 2.1vh;
  font-weight: 700;
  /* line-height: 2.5rem; */
  line-height: 2.7vh;
  letter-spacing: -0.4099999964237213px;
  text-align: left;
  margin: 0;
  width: 100%;
  font-family: "Actay Wide", sans-serif;
}
.instruction__text_colored {
  color: #ffbe30;
}
.text_final_stage {
  margin-bottom: 20vh;
}
.instruction__text-tip {
  font-weight: 400;
  /* font-size: 1.1rem;
  line-height: 1.6rem; */
  font-size: 1.7vh;
  line-height: 2vh;
  max-width: 70%;
  color: var(--blue);
}
.instruction__link-box {
  /* display: flex;
  flex-direction: column; */
  margin: 2vh 0 3vh 0;
  width: 100%;
}

.instruction__link-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2vh;
}

.instruction__link-row_bottom {
  width: "100%";
  display: flex;
  justify-content: space-evenly;
}

.instruction__link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction__link-item_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction__link-name {
  font-size: 1.4vh;
  color: #aeaeae;
  font-weight: 400;
  margin-top: 0.5vh;
}

.instruction__link-img {
  /* max-width: 30px;
  max-height: 30px; */
  height: 6vh;
}

.instruction__link-focused {
  filter: invert(48%) sepia(23%) saturate(3654%) hue-rotate(192deg)
    brightness(96%) contrast(99%);
  /* max-width: 30px;
  max-height: 30px; */
  height: 6vh;
}

.instruction__link-name-focused {
  font-size: 1.4vh;
  color: #aeaeae;
  font-weight: 400;
  margin-top: 0.5vh;
  text-decoration: underline;
  color: var(--blue);
}

.instruction__gallery {
  margin: 34px 0 34px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.instruction__step-image.vertical {
  aspect-ratio: 574/398;
  max-width: 100%;
  max-height: 30vh;
  z-index: 1;
}
.instruction__step-image.horizontal {
  aspect-ratio: 298/579;
  width: 100%;
  z-index: 1;
}
.instruction__step-image.android {
  aspect-ratio: 282/642;
  width: 96%;
  z-index: 1;
}
.instruction__step-image.windows {
  aspect-ratio: 400 / 650;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}
.instruction__step-image-container.windows {
  aspect-ratio: 400 / 650;
  max-width: 47%;
  max-height: 40vh;
  z-index: 1;
}
.instruction__gallery-row.vertical {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.instruction__gallery-row.windows {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.instruction__gallery-row.horizontal {
  position: relative;
  margin-bottom: 0 !important;
  display: flex;
  flex-wrap: nowrap;
}
.instruction__gallery-row.first {
  margin-bottom: 20px;
}

/* .instruction__gallery-row.first {
  height: 174px;
} */
/* .instruction__gallery-row.second {
  height: 176px;
} */
.instruction__step-tooltip {
  font-size: 1.9vh;
  line-height: 2rem;
  white-space: nowrap;
  font-weight: 700;
  color: var(--blue);
  position: absolute;
  font-family: "Actay Wide", sans-serif;
}
.instruction__text-case {
  margin-top: 2vh;
  width: 100%;
}
.instruction__step-rectangle {
  border: 1px solid #ffbe30;
  border-radius: 2.12811px;
  z-index: 2;
  position: absolute;
}
/* MACOS */
.instruction__gallery-macos {
  margin: 3vh 0 3vh 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.instruction__step-rectangle.one.macos {
  width: 13px;
  height: 13px;
  bottom: 78%;
  left: 40%;
}
.instruction__step-rectangle.one.macos::before {
  content: "";
  position: absolute;
  width: 125px;
  height: 1px;
  background-color: #ffbe30;
  top: 38px;
  left: 6px;
  z-index: 2;
}
.instruction__step-rectangle.one.macos::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  left: 130px;
  top: 37px;
}
.instruction__step-rectangle.one-vert.macos::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -67px;
  right: -122px;
  z-index: 2;
}
.instruction__step-tooltip.one.macos {
  bottom: 66%;
  right: 14%;
  z-index: 3;
}
/*-------*/
.instruction__step-rectangle.two.macos {
  width: 13px;
  height: 13px;
  top: 61px;
  right: 51px;
}
.instruction__step-rectangle.two.macos::before {
  content: "";
  position: absolute;
  width: 94px;
  height: 1px;
  background-color: #ffbe30;
  bottom: -26px;
  left: -87px;
  z-index: 2;
}
.instruction__step-rectangle.two.macos::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 99px;
  bottom: -27px;
}
.instruction__step-rectangle.two-vert.macos::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -100px;
  right: -228px;
  z-index: 2;
}
.instruction__step-tooltip.two.macos {
  bottom: 50%;
  left: 38%;
  z-index: 3;
}
/* ipad */
.instruction__gallery-ipad {
  margin: 12px 0 12px 0;
  width: 287px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.instruction__step-rectangle.one.ipad {
  width: 9px;
  height: 9px;
  bottom: 182px;
  left: 274px;
}
.instruction__step-rectangle.one.ipad::before {
  content: "";
  position: absolute;
  width: 89px;
  height: 1px;
  background-color: #ffbe30;
  top: 34px;
  left: -85px;
  z-index: 2;
}
.instruction__step-rectangle.one.ipad::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  left: -87px;
  top: 33px;
}
.instruction__step-rectangle.one-vert.ipad::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -41px;
  right: -279px;
  z-index: 2;
}
.instruction__step-tooltip.one.ipad {
  bottom: 159px;
  left: 188px;
  z-index: 3;
}
/*-------*/
.instruction__step-rectangle.two.ipad {
  width: 58px;
  height: 10px;
  top: 38px;
  right: 2px;
}
.instruction__step-rectangle.two.ipad::before {
  content: "";
  position: absolute;
  width: 125px;
  height: 1px;
  background-color: #ffbe30;
  bottom: -87px;
  left: -97px;
  z-index: 2;
}
.instruction__step-rectangle.two.ipad::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 154px;
  bottom: -88px;
}
.instruction__step-rectangle.two-vert.ipad::before {
  content: "";
  position: absolute;
  height: 87px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -135px;
  right: -254px;
  z-index: 2;
}
.instruction__step-tooltip.two.ipad {
  bottom: 64px;
  left: 127px;
  z-index: 3;
}
/*--------*/
.instruction__step-rectangle.three.ipad {
  width: 19px;
  height: 18px;
  top: 33px;
  right: 133px;
}
.instruction__step-rectangle.three.ipad::before {
  content: "";
  position: absolute;
  width: 99px;
  height: 1px;
  background-color: #ffbe30;
  bottom: -46px;
  left: -89px;
  z-index: 2;
}
.instruction__step-rectangle.three.ipad::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 107px;
  bottom: -47px;
}
.instruction__step-rectangle.three-vert.ipad::before {
  content: "";
  position: absolute;
  height: 45px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -97px;
  right: -143px;
  z-index: 2;
}
.instruction__step-tooltip.three.ipad {
  bottom: 103px;
  left: 44px;
  z-index: 3;
}
/* ios */
.instruction__gallery-ios {
  margin: 60px 0 60px 0;
  width: 287px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.instruction__step-rectangle.one.ios {
  width: 9px;
  height: 9px;
  bottom: 263px;
  left: 122px;
}
.instruction__step-rectangle.one.ios::before {
  content: "";
  position: absolute;
  width: 119px;
  height: 1px;
  background-color: #ffbe30;
  top: -31px;
  left: -114px;
  z-index: 2;
}
.instruction__step-rectangle.one.ios::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  left: -115px;
  top: -32px;
}
.instruction__step-rectangle.one-vert.ios::before {
  content: "";
  position: absolute;
  height: 31px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -5px;
  right: -127px;
  z-index: 2;
}
.instruction__step-tooltip.one.ios {
  bottom: 304px;
  left: 7px;
  z-index: 3;
}
/*-------*/
.instruction__step-rectangle.two.ios {
  width: 78px;
  height: 13px;
  top: 44px;
  right: 8px;
}
.instruction__step-rectangle.two.ios::before {
  content: "";
  position: absolute;
  width: 77px;
  height: 1px;
  background-color: #ffbe30;
  bottom: 82px;
  left: 15px;
  z-index: 2;
}
.instruction__step-rectangle.two.ios::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: -15px;
  bottom: 81px;
}
.instruction__step-rectangle.two-vert.ios::before {
  content: "";
  position: absolute;
  height: 70px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -44px;
  right: -71px;
  z-index: 2;
}
.instruction__step-tooltip.two.ios {
  bottom: 304px;
  left: 83px;
  z-index: 3;
}
/*--------*/
.instruction__step-rectangle.three.ios {
  width: 25px;
  height: 24px;
  top: 37px;
  right: 58px;
}
.instruction__step-rectangle.three.ios::before {
  content: "";
  position: absolute;
  width: 124px;
  height: 1px;
  background-color: #ffbe30;
  bottom: -263px;
  left: 13px;
  z-index: 2;
}
.instruction__step-rectangle.three.ios::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: -114px;
  bottom: -264px;
}
.instruction__step-rectangle.three-vert.ios::before {
  content: "";
  position: absolute;
  height: 262px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -324px;
  right: -72px;
  z-index: 2;
}
.instruction__step-tooltip.three.ios {
  bottom: -44px;
  left: 131px;
  z-index: 3;
}
/* android */
.instruction__gallery-android {
  margin: 60px 0 60px 0;
  width: 287px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.instruction__step-rectangle.one.android {
  width: 10px;
  height: 10px;
  bottom: 287px;
  left: 100px;
}
.instruction__step-rectangle.one.android::before {
  content: "";
  position: absolute;
  width: 76px;
  height: 1px;
  background-color: #ffbe30;
  top: -45px;
  left: -70px;
  z-index: 2;
}
.instruction__step-rectangle.one.android::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  left: -72px;
  top: -46px;
}
.instruction__step-rectangle.one-vert.android::before {
  content: "";
  position: absolute;
  height: 45px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -15px;
  right: -106px;
  z-index: 2;
}
.instruction__step-tooltip.one.android {
  bottom: 343px;
  left: 29px;
  z-index: 3;
}
/*-------*/
.instruction__step-rectangle.two.android {
  width: 49px;
  height: 11px;
  top: 28px;
  right: 8px;
}
.instruction__step-rectangle.two.android::before {
  content: "";
  position: absolute;
  width: 121px;
  height: 1px;
  background-color: #ffbe30;
  bottom: 68px;
  left: -85px;
  z-index: 2;
}
.instruction__step-rectangle.two.android::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 132px;
  bottom: 67px;
}
.instruction__step-rectangle.two-vert.android::before {
  content: "";
  position: absolute;
  height: 58px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -28px;
  right: -121px;
  z-index: 2;
}
.instruction__step-tooltip.two.android {
  bottom: 343px;
  left: 0px;
  z-index: 3;
}
/*--------*/
.instruction__step-rectangle.three.android {
  width: 18px;
  height: 17px;
  top: 272px;
  right: 8px;
}
.instruction__step-rectangle.three.android::before {
  content: "";
  position: absolute;
  width: 86px;
  height: 1px;
  background-color: #ffbe30;
  bottom: -69px;
  left: 9px;
  z-index: 2;
}
.instruction__step-rectangle.three.android::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: -79px;
  bottom: -70px;
}
.instruction__step-rectangle.three-vert.android::before {
  content: "";
  position: absolute;
  height: 69px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -358px;
  right: -125px;
  z-index: 2;
}
.instruction__step-tooltip.three.android {
  bottom: -44px;
  left: 147px;
  z-index: 3;
}
/* windows */
.instruction__gallery-windows {
  margin: 3vh 0 3vh 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.instruction__step-rectangle.one.windows {
  width: 69px;
  height: 12px;
  bottom: 30px;
  left: 32px;
}
.instruction__step-rectangle.one.windows::before {
  content: "";
  position: absolute;
  width: 61px;
  height: 1px;
  background-color: #ffbe30;
  top: -45px;
  left: -26px;
  z-index: 2;
}
.instruction__step-rectangle.one.windows::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  left: -28px;
  top: -46px;
}
.instruction__step-rectangle.one-vert.windows::before {
  content: "";
  position: absolute;
  height: 45px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -45px;
  right: -68px;
  z-index: 2;
}
.instruction__step-tooltip.one.windows {
  bottom: 52%;
  left: 3%;
  z-index: 3;
}
/*-------*/
.instruction__step-rectangle.two.windows {
  width: 16px;
  height: 16px;
  top: 137px;
  right: 12px;
}
.instruction__step-rectangle.two.windows::before {
  content: "";
  position: absolute;
  width: 98px;
  height: 1px;
  background-color: #ffbe30;
  bottom: 66px;
  left: -89px;
  z-index: 2;
}
.instruction__step-rectangle.two.windows::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 103px;
  bottom: 65px;
}
.instruction__step-rectangle.two-vert.windows::before {
  content: "";
  position: absolute;
  height: 49px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -49px;
  right: -266px;
  z-index: 2;
}
.instruction__step-tooltip.two.windows {
  bottom: 52%;
  right: 20%;
  z-index: 3;
}
/*--------*/
.instruction__step-rectangle.three.windows {
  width: 106px;
  height: 32px;
  top: 88px;
  right: 166px;
}
.instruction__step-rectangle.three-vert.windows {
  border-color: transparent !important;
}
.instruction__step-rectangle.one-vert.windows {
  border-color: transparent !important;
}
.instruction__step-rectangle.two-vert.windows {
  border-color: transparent !important;
}
.instruction__step-rectangle.three.windows::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: #ffbe30;
  bottom: 61px;
  left: -7px;
  z-index: 2;
}
.instruction__step-rectangle.three.windows::after {
  z-index: 2;
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  background-color: #ffbe30;
  border-radius: 50%;
  right: 111px;
  bottom: 60px;
}
.instruction__step-rectangle.three-vert.windows::before {
  content: "";
  position: absolute;
  height: 30px;
  width: 1px;
  background-color: #ffbe30;
  bottom: -1px;
  right: -66px;
  z-index: 2;
}
.instruction__step-tooltip.three.windows {
  bottom: 68%;
  left: 2%;
  z-index: 3;
}
.instruction__button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.last_instruction {
  height: 80vh;
}
.instruction__title {
  color: var(--blue);
  text-align: left;
  width: 100%;
  margin: 5vh 0 5vh 0;
  font-size: 3.2rem;
  line-height: 3.4rem;
  font-weight: 700;
  font-family: "Actay Wide", sans-serif;
}
.instruction__button-top {
  /* height: 5%;
  min-height: 56px; */
  padding: 0 3vh;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  text-align: start;
  padding-left: 2vh;
  font-size: 1.2rem;
  color: var(--blue);
  display: flex;
  align-items: center;
  line-height: 0;
  background-color: transparent;
  border: transparent;
  margin-bottom: 10px;
  margin-top: 1rem;
}

.instruction__button-corner {
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  background-color: transparent;
  border-top: 2px solid #348ff3;
  border-left: 2px solid #348ff3;
  position: absolute;
  left: 0;
}
.instruction__button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 0;
}
.instruction__button-wrapper.hidden {
  display: none;
}
.instruction__tips {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--blue);
  margin-top: 14px;
  width: 90%;
}

@media screen and (min-height: 650px) {
  .instruction {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* padding: 0 35px 35px 35px; */
    padding: 10% 10%;
    box-sizing: border-box;
    font-family: "Actay", sans-serif;
  }
}

/* 
@media screen and (max-height: 740px) {
  
}

@media screen and (max-height: 650px) {
  .instruction__link-box {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }
  .instruction__gallery {
    margin: 5% 0;
  }
  .instruction__button-top {
    margin-bottom: 0;
  }
}

@media screen and (max-height: 630px) {
  .instruction__link-box {
    margin: 10px auto 0px auto;
  }
}

@media screen and (max-height: 600px) {
  .instruction__text { 
    font-size: 1.5rem;
  }
}

@media screen and (max-height: 570px) {
  .instruction__button-corner.first {
    top: 32px;
    width: 8px;
    height: 8px;
  }

  .instruction__button-top.first {
    align-items: flex-end;
    min-height: 40px;
    margin-bottom: 10px;
  }
}

@media screen and (max-height: 552px) {
  .instruction__gallery {
    margin: 0;
    transform: scale(0.9);
  }
  .instruction__content {
    max-height: 65%;
  }
}

@media screen and (max-height: 530px) {
  .instruction__button-wrapper {
    margin-top: 15px;
  }

  .instruction__button-corner.first {
    top: 30px;
    width: 8px;
    height: 8px;
  }

  .instruction__button-top.first {
    align-items: flex-end;
    min-height: 40px;
    margin-bottom: 5px;
  }
}


@media screen and (max-height: 520px) {
  .instruction__link-box {
    margin: 10px auto 0px auto;
  }
} */

/* @media screen and (max-height: 510px) {
  .instruction__gallery-row.first {
    height: 166px;
  }
  .instruction__gallery-row.second {
    height: 172px;
  }
} */
